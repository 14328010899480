import React from "react"
import { Container, Card, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaSpotify } from "@react-icons/all-files/fa/FaSpotify"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { SiApplemusic } from "@react-icons/all-files/si/SiApplemusic"
import { FaYoutubeSquare } from "@react-icons/all-files/fa/FaYoutubeSquare"
import { FaSoundcloud } from "@react-icons/all-files/fa/FaSoundcloud"

const Billboard = ({ image }) => {
  return (
    <Card className="billboard bg-dark text-white rounded-0 border-0">
      <Img loading="lazy" className="card-img" fluid={image} alt="Xandor" />
      {/* <Card.Img src={image.src} alt="Card image" /> */}
      <Card.ImgOverlay className="d-flex flex-column justify-content-center">
        <Container>
          <Row className="socials justify-content-center">
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Instagram"
                href="https://www.instagram.com/xandor/"
              >
                <FaInstagram />
              </a>
            </Col>
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Spotify"
                href="https://bit.ly/XandorSpotify"
              >
                <FaSpotify />
              </a>
            </Col>
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Apple Music"
                href="https://music.apple.com/us/artist/xandor/1429390047"
              >
                <SiApplemusic />
              </a>
            </Col>
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Youtube Music"
                href="https://www.youtube.com/channel/UClV0OHOYmPBDeKrUxxmPcew"
              >
                <FaYoutubeSquare />
              </a>
            </Col>
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Soundcloud"
                href="https://soundcloud.com/xandor"
              >
                <FaSoundcloud />
              </a>
            </Col>
            <Col xs="2" lg="1">
              <a
                type="link"
                aria-label="Xandor Facebook"
                href="https://facebook.com/xandormusic"
              >
                <FaFacebook />
              </a>
            </Col>
          </Row>
        </Container>
      </Card.ImgOverlay>
    </Card>
  )
}

export default Billboard
